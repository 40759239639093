.header {
  margin-bottom: 16px;

  .headerMain {
    display: flex;
    justify-content: space-between;

    .headingWrapper {
      display: flex;
      align-items: center;

      > :not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  > *:not(:last-child) {
    margin-bottom: 30px;
  }
}
