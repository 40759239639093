@use '../borders';
@use '../colors';
@use '../mixins';
@use '../../fonts/fonts';

.card {
  min-width: 204px;
  height: 133px;
  background-color: colors.$white;
  border-radius: borders.$radius-lg;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
  padding: 26px 26px 21px 26px;
  overflow: auto;

  .text {
    display: flex;
    flex-direction: column;
  }

  .statValue {
    font-variant-numeric: ordinal;
    display: inline-flex;
    align-items: center;
    height: 28px;
    @include mixins.style-font(28px, 100%, 500);
    color: colors.$blue;
    margin-top: 16px;
  }

  .subTotal {
    @include mixins.style-font(14px, 100%, 500);
    color: colors.$cardSubTotal;
    margin-top: 14px;
  }

  &Icon {
    min-width: 232px;
    height: 108px;
    display: flex;
    align-items: center;
    padding: 24px;

    .statValue {
      @include mixins.style-font(32px, 100%, 500);
      margin-top: 10px;
      height: 32px;
    }

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }
}
