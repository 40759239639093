.apiKey {
  display: flex;
  flex-direction: column;

  &InputRow {
    display: flex;
    align-items: center;

    .inputWrapper {
      width: 100%;

      .input {
        cursor: pointer;
      }
    }

    .copyIcon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  > *:not(:last-child) {
    margin-bottom: 10px;
  }
}
