.wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .header {
    width: 100%;
    display: flex;

    .title {
      margin-bottom: 24px;
    }

    > *:not(:last-child) {
      margin-right: 32px;
    }
  }
}
