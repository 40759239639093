@use '../colors';
@use '../mixins';

.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  input[type='checkbox'] {
    display: none;
    &:checked {
      + {
        .switch {
          &::before {
            transform: translateX(25px);
            background-color: colors.$white;
          }
          background-color: colors.$blue;
        }
      }
    }
  }
  .switch {
    position: absolute;
    cursor: pointer;
    background-color: colors.$lightBlue;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
    &::before {
      position: absolute;
      content: '';
      left: 2px;
      top: 2px;
      width: 21px;
      height: 21px;
      background-color: colors.$blue;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
  }
}
.text {
  margin-top: 5px;
  margin-left: -10px;
  @include mixins.style-font(16px, 14px, 500);
}
