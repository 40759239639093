.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .main {
    padding: 0 111px 50px 111px;
  }

  > *:not(:last-child) {
    margin-bottom: 49px;
  }
}
