@use '../colors';
@use '../borders';

.cardTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 252px;
  padding: 20px 8px 20px 16px;
  background-color: colors.$blue;
  border-radius: borders.$radius-lg;

  > *:not(:last-child) {
    margin-bottom: 22px;
  }
}
