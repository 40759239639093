.filters {
  display: grid;
  gap: 8px;

  &Admin {
    grid-template-columns: 189px 189px 189px 189px auto;
  }

  &Merchant {
    grid-template-columns: 189px 189px auto;
  }
}
