.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px;

  &Left {
    display: flex;

    > *:not(:last-child) {
      margin-right: 126px;
    }
  }

  .icon {
    margin-top: 27px;
    cursor: pointer;
  }
}
