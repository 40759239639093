.page {
  width: 100%;
  height: 100%;
  display: flex;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1216px;
  }

  .heading {
    margin-bottom: 30px;
  }
}
