@use '../../../mixins';
@use '../../../colors';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 274px;
  padding: 17px 24px 14px 24px;
  border-bottom: 1px solid colors.$border-datepicker;

  .arrow {
    border: none;
    background: none;
    cursor: pointer;
  }

  .date {
    @include mixins.style-font(14px, 14px, 500);

    .month {
      color: colors.$black;
    }

    .year {
      color: colors.$text-grey;
    }
  }
}
