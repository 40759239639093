@use '../../components/mixins';
@use '../../components/colors';

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;

  .image {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .main {
    height: auto;

    .image {
      display: initial;
    }
  }
}
