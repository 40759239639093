@use '../../../../components/colors';
@use '../../../../components/borders';

.loaderWrapper {
  width: 170px;
}

.qr {
  width: 170px;
  height: 170px;
  border: 1px solid colors.$border-grey;
  border-radius: borders.$radius-sm;
}
