@use '../../../components/mixins';
@use '../../../components/colors';

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;

  .image {
    display: none;
  }

  .copyright {
    position: absolute;
    bottom: 0;
    left: 0;
    @include mixins.style-font(14px, 17px, 400);
    color: colors.$lightGrey;
  }
}

@media only screen and (min-width: 768px) {
  .main {
    height: auto;

    .image {
      display: initial;
    }
  }
}
