@use '../colors';
@use '../borders';
@use '../mixins';

div.react-select--is-disabled .react-select__control {
  background: none;
}

.my-react-select__primary {
  &.react-select__filter-view .react-select__control {
    height: 27px;
    @include mixins.style-font(12px, 120%, 400);
    border-radius: 10px;
    padding-left: 12px;
  }

  .react-select__control {
    height: 47px;
    border: 1px solid colors.$border-grey;
    border-radius: borders.$radius-md;
    padding-left: 18px;
    @include mixins.style-font(16px, 120%, 400);

    .react-select__indicator {
      padding-right: 10px;
    }

    &:hover {
      border-radius: borders.$radius-md;
    }
  }
}

.my-react-select__secondary .react-select__control {
  border-bottom: 1px solid colors.$border-grey-secondary;
  @include mixins.style-font(16px, 120%, 400);
}

.my-react-select__table {
  &.react-select__filter-view {
    @include mixins.style-font(12px, 120%, 400);
    div.react-select__control {
      border-color: colors.$filter-border;

      .react-select__placeholder {
        color: colors.$filter-placeholder;
      }
    }
  }

  .react-select__control {
    border-bottom: 1px solid colors.$border-grey-secondary;
    @include mixins.style-font(12px, 120%, 400);
  }
}

div.react-select__control {
  min-height: 24px;
  cursor: pointer;
  border: none;
  border-radius: 0px;
  outline: 0;
  caret-color: transparent;

  &.react-select__control--is-focused,
  &:focus {
    box-shadow: none;
    border-color: colors.$blue;
  }

  &:hover {
    border-color: colors.$blue;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__value-container {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    align-items: center;
    padding: 0 10px 0 0;

    .react-select__placeholder {
      display: flex;
      height: 100%;
      align-items: center;
      color: colors.$placeholder-grey-secondary;
    }

    * {
      cursor: pointer;
      margin: 0;
    }
  }

  .react-select__indicator {
    padding: 0;
  }

  .react-select__indicators {
    justify-content: center;
    align-items: center;
  }
}

.react-select__control:hover {
  border-radius: 0px;
  box-shadow: none;
}

div.react-select__menu {
  margin-top: 0px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);

  .react-select__menu-list {
    padding: 0;
    border-radius: 0px 0px borders.$radius-xs borders.$radius-xs;

    .react-select__option {
      @include mixins.style-font(12px, 140%, 500);
      padding-left: 10px;
      padding-top: 5px;
      background-color: colors.$white;
      color: colors.$black;
      cursor: pointer;
    }

    .react-select__option:hover {
      background-color: #f2f5f9;
      color: colors.$black;
    }

    div.react-select__option .react-select__option--is-selected {
      color: #ce1e93 !important;
      color: red !important;
    }
  }
}
