@use '../../colors';
@use '../../borders';
@use '../../mixins';

.wrapper {
  display: flex;
  position: absolute;
  align-items: center;
  padding: 6px 12px;
  background-color: colors.$popup-background;
  box-shadow: 0px 4px 10px colors.$popup-shadow;
  border-radius: borders.$radius-sm;
  color: colors.$white;
  @include mixins.style-font(13px, 20px, 400);
  z-index: 100;
  right: calc(100% + 16px);
  visibility: hidden;

  &Left {
    visibility: visible;
  }

  &Right {
    right: initial;
    left: 41px;
    visibility: visible;
  }

  .copyIcon {
    display: flex;
    @include mixins.center-flex();
  }

  .copyIcon,
  .closeIcon {
    cursor: pointer;
  }

  > *:not(:last-child) {
    margin-right: 16px;
  }
}
