@use '../../borders';
@use '../../colors';
@use '../../mixins';

.react-datepicker-popper .react-datepicker {
  width: 276px;
  border-radius: borders.$radius-md;
  border-color: colors.$border-datepicker;

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    padding-top: 0;
    background-color: colors.$white;
    border-radius: borders.$radius-md borders.$radius-md 0 0;
    border-bottom: none;

    .react-datepicker__current-month {
      text-align: center;
      width: 274px;
    }

    * {
      color: colors.$black;
    }
  }

  .react-datepicker__navigation-icon::before {
    border-color: colors.$blue;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: center;
    padding: 9px 0;

    // days of the week
    .react-datepicker__day-name {
      display: block;
      @include mixins.style-font(8px, 142%, 700);
      width: 35px;
      margin: 0;
      color: colors.$text-lightgrey;
    }
  }

  .react-datepicker__month {
    margin-bottom: 4px;

    .react-datepicker__day {
      width: 34px;
      height: 34px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0;

      &--selected,
      &--selected:hover,
      &--keyboard-selected,
      &.react-datepicker__day--keyboard-selected:hover,
      &--in-range,
      &--in-range.react-datepicker__day:hover,
      &--in-range.react-datepicker__day.react-datepicker__day--today:hover {
        color: colors.$white;
        background-color: colors.$blue;
        border-radius: borders.$radius-md;
      }

      &--today {
        font-weight: 600;
        background: none;

        &.react-datepicker__day--selected,
        &.react-datepicker__day--keyboard-selected {
          font-weight: 600;
          background: colors.$blue;
        }
      }

      &--outside-month {
        color: colors.$disabled-grey;
      }

      &:hover,
      &.react-datepicker__day--today:hover,
      &--in-selecting-range {
        border-radius: borders.$radius-md;
        background-color: colors.$hover-grey;
      }
    }
  }

  .react-datepicker__input-time-container {
    margin-top: 0;
    margin-bottom: 16px;
  }
}
