@use '../colors';
@use '../borders';

.Toastify__toast {
  height: 42px;
  padding: 12px;
  background: colors.$toast-background;
  border: 1px solid colors.$toast-border;
  border-radius: borders.$radius-toast;
  box-shadow: none;
  text-align: center;
}
