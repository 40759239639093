@font-face {
  font-family: 'Inter';
  src: url('./Inter-Regular.ttf');
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Medium.ttf');
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-SemiBold.ttf');
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Bold.ttf');
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
}
