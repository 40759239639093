@use '../../../colors';
@use '../../../borders';

.wrapperContainer {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(15, 31, 38, 0.7);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 273px;
  background-color: colors.$white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: borders.$radius-lg;
  padding: 24px;

  &TFA {
    flex-direction: row;
  }
  > *:not(:last-child) {
    margin-bottom: 24px;
  }
}
