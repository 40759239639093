// https://codepen.io/slyka85/pen/QvBQPb/
// https://freebiesupply.com/blog/css-loaders/
@use '../_colors';

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  position: relative;
  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle;
}

/*LOADER-4*/

.loader-4 {
  border-width: 7px;
  border-style: double;
  -webkit-animation: ball-turn 1s cubic-bezier(0.05, 0.08, 0.43, 1.6) infinite;
  animation: ball-turn 1s cubic-bezier(0.05, 0.08, 0.43, 1.6) infinite;
}

.loader-4:before,
.loader-4:after {
  content: '';
  position: absolute;
  border-radius: 50%;
  bottom: 0;
}

.loader-4:after {
  top: 0;
}

.xs {
  width: 20px;
  height: 20px;

  &::before,
  &::after {
    width: 8px;
    height: 8px;
  }

  &::before {
    right: 7px;
  }

  &::after {
    left: 7px;
  }
}

.sm {
  width: 40px;
  height: 40px;

  &::before,
  &::after {
    width: 12px;
    height: 12px;
  }

  &::before {
    right: 22px;
  }

  &::after {
    left: 22px;
  }
}

.md {
  width: 60px;
  height: 60px;

  &::before,
  &::after {
    width: 12px;
    height: 12px;
  }

  &::before {
    right: 37px;
  }

  &::after {
    left: 37px;
  }
}

.primary {
  border-color: colors.$loader-primary;

  &::after,
  &::before {
    background-color: colors.$loader-primary;
  }
}

.secondary {
  border-color: colors.$loader-secondary;

  &::after,
  &::before {
    background-color: colors.$loader-secondary;
  }
}

@-webkit-keyframes ball-turn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ball-turn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
