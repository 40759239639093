@use '../../mixins';
@use '../../colors';
@use '../../borders';

.table {
  margin-bottom: 30px;
  border-collapse: collapse;
  border-spacing: 0;

  tbody tr:hover {
    background-color: colors.$row-hover;
  }

  .merchRow {
    height: 34px;
  }

  .transRow {
    height: 71px;
  }

  .noDataWrapper {
    &:hover {
      background: none;
    }

    .noData {
      @include mixins.center-flex();
      @include mixins.style-font(12px, 140%, 500);
      width: 100%;
      padding: 128px 0;
    }
  }

  th,
  td {
    box-sizing: content-box;
    @include mixins.style-font(12px, 140%, 500);
    vertical-align: top;
    text-align: left;
    margin: 0;
    padding: 0;
    &:not(:last-child) {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  th:not(:last-child) {
    padding-right: 12px;
  }

  .merchIndent {
    padding: 4px 6px 4px 0;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .transIndent {
    padding: 10px 6px 10px 0;
  }

  td {
    &:first-child {
      border-radius: borders.$radius-sm 0 0 borders.$radius-sm;
    }

    &:last-child {
      border-radius: 0 borders.$radius-sm borders.$radius-sm 0;
    }

    .dateTime {
      display: flex;
      flex-direction: column;

      .time {
        @include mixins.style-font(10px, 140%, 400);
      }
    }
  }
}
