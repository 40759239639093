@use '../mixins';
@use '../colors';

.link {
  display: flex;
  align-items: center;
  @include mixins.style-font(16px, 19px, 700);
  color: colors.$blue;
  cursor: pointer;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}
