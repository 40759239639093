@use '../borders';
@use '../colors';
@use '../mixins';

.badge {
  height: 30px;
  overflow: hidden;
  text-transform: uppercase;
  white-space: nowrap;
  @include mixins.center-flex();
  @include mixins.style-font(12px, 14px, 700);
  border: 1px solid;
  border-radius: borders.$radius-badge;
}

.sm {
  width: 100px;
}

.lg {
  width: 150px;
}

.crimson {
  color: colors.$crimson;
  border-color: colors.$lightCrimson;
}

.lightCrimson {
  color: colors.$badge-lightCrimson;
  border-color: colors.$lightCrimson;
}

.yellow {
  color: colors.$yellow;
  border-color: colors.$lightYellow;
}

.green {
  color: colors.$green;
  border-color: colors.$lightGreen;
}

.lightBlue {
  color: colors.$popup-multiple-header;
}
