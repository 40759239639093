@use '../../mixins';
@use '../../colors';

.explanation {
  @include mixins.style-font(12px, 140%, 400);
  color: colors.$black;
  opacity: 0.2;
  text-align: center;
  vertical-align: middle;
}
