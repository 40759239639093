@use '../../colors';
@use '../../mixins';

.datepicker {
  width: 100%;
  height: 24px;
  border: 0;
  border-bottom: 1px solid colors.$border-grey-secondary;
  color: colors.$black;
  padding-right: 28px;
  @include mixins.style-font(16px, 100%, 400);

  &.filterView {
    border-color: colors.$filter-border;

    &::placeholder {
      color: colors.$filter-placeholder;
    }
  }

  &:disabled {
    background: none;
  }

  &::placeholder {
    @include mixins.style-font(16px, 100%, 400);
    color: colors.$placeholder-grey-secondary;
  }

  &.tableView {
    @include mixins.style-font(12px, 100%, 400);
    padding-right: 20px;

    &::placeholder {
      @include mixins.style-font(12px, 100%, 400);
    }
  }

  &:hover,
  &:focus {
    outline: none;
    border-color: colors.$blue;
  }
}
