.wrapper {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 24px;
  }

  .badges {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pair {
      display: flex;

      > *:not(:last-child) {
        margin-right: 10px;
      }
    }

    > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
