@use '../../colors';
@use '../../borders';
@use '../../mixins';

.rangepicker {
  width: 100%;
  border: 0;
  color: colors.$black;
  padding-right: 28px;
  @include mixins.style-font(16px, 100%, 400);

  &.primary {
    height: 47px;
    border: 1px solid colors.$border-grey-secondary;
    border-radius: borders.$radius-md;
    padding-left: 18px;

    &.filterView {
      height: 27px;
      padding-left: 11px;
      border-radius: 10px;
      @include mixins.style-font(12px, 100%, 400);
    }
  }

  &.secondary {
    height: 24px;
    border-bottom: 1px solid colors.$border-grey-secondary;

    &.filterView {
      border-color: colors.$filter-border;

      &::placeholder {
        color: colors.$filter-placeholder;
      }
    }
  }

  &:disabled {
    background: none;
  }

  &::placeholder {
    @include mixins.style-font(16px, 100%, 400);
    color: colors.$placeholder-grey-secondary;
    &.filterView {
      @include mixins.style-font(12px, 100%, 400);
    }
  }

  &:hover,
  &:focus {
    outline: none;
    border-color: colors.$blue;
  }
}
