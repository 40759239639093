@use '../mixins';
@use '../colors';

.ul {
  display: flex;
  list-style: none;

  > li {
    display: flex;
    align-items: flex-end;

    > a {
      padding-top: 33px;
      text-decoration: none;
      margin-left: 33px;
      cursor: pointer;
    }
  }

  .nonActive {
    @include mixins.style-font(16px, 160%, 500);
    color: colors.$grey;
  }

  .activeStyle {
    @include mixins.style-font(16px, 160%, 500);
    color: colors.$blue;
    border-top: 3px solid colors.$blue;
  }
}
