$blue: #3190ff;
$lightBlue: #e4f1ff;
$white: #ffffff;
$grey: #343d5b;
$black: #000000;
$lightGrey: #a9aebd;
$dullGrey: #bcc0cd;
$crimson: #df1647;
$lightCrimson: #df164740;
$yellow: #ffcb68;
$lightYellow: #ffcb6840;
$green: #00c797;
$lightGreen: #00c79740;

$border-grey: #e7e7e7;
$border-grey-secondary: #dae5f0;
$border-datepicker: #efefef;

$placeholder-grey: #aeaeae;
$placeholder-grey-secondary: #bec9d7;

$hover-grey: #edf2f8;

$disabled-grey: #aeb4bc;

$row-blue: #53a2ff;
$row-hover: #f9fafc;

$text-grey: #a5aab2;
$text-lightgrey: #aeb4bc;

$cardSubTotal: #acbed2;

$pagination-grey: #f4f6f9;
$pagination-darkgrey: #d5dbe2;

$modal-border: #e6e6e6;

$loader-primary: #53a2ff;
$loader-secondary: #e4f1ff;

$popup-background: #0085ff;
$popup-shadow: #dadfef;

$toast-background: #ffffff;
$toast-border: #3190ff;

$scrollbar-thumb: #f5f5f5;
$scrollbar-track: #ffffff;

$filter-placeholder: #87bdfc;
$filter-border: #3190ff;

$badge-lightCrimson: #ffc1e3;

$popup-multiple-header: #67adff;
$popup-multiple-background: #42a0fa;
$popup-text: #fff;
