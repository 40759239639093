@use '../../colors';
@use '../../borders';

.popup {
  position: absolute;
  background-color: colors.$popup-multiple-background;
  border-radius: borders.$radius-sm;
  z-index: 100;
  right: calc(100% + 16px);
  top: 50%;

  &Right {
    right: initial;
    left: 41px;
  }

  .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background-color: colors.$popup-multiple-header;
    border-radius: borders.$radius-sm borders.$radius-sm 0 0;

    .closeIcon {
      cursor: pointer;
    }
  }
}
