.chart {
  display: flex;
  flex-direction: column;

  .loaderWrapper {
    width: 585px;
    height: 333px;
  }

  > *:not(:last-child) {
    margin-bottom: 40px;
  }
}
