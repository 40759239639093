@use '../../../mixins';
@use '../../../colors';

.item {
  display: flex;
  padding: 8px 12px;

  .labelWrapper {
    width: 80px;

    .label {
      @include mixins.style-font(13px, 20px, 600);
      color: colors.$popup-text;
    }
  }

  .valueWrapper {
    display: flex;
    align-items: center;

    .value {
      @include mixins.style-font(13px, 20px, 400);
      color: colors.$popup-text;
    }

    .copyIcon {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    > *:not(:last-child) {
      margin-right: 8px;
    }
  }
}
