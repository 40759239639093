@use '../../mixins';

.modal {
  width: 340px;
}

.noData {
  @include mixins.style-font(16px, 100%, 500);
  @include mixins.center-flex();
  padding: 30px;
}

.apiKeys {
  max-height: 300px;
  overflow-y: auto;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
}
