@use '../colors';

.sm {
  width: 83px;
}

.lg {
  width: 87px;
}

.xl {
  width: 110px;
}

.xxl {
  width: 165px;
}

.full {
  width: 100%;
}

.selectWrapper {
  position: relative;

  .closeBtn {
    position: absolute;
    top: 50%;
    right: 17px;
    transform: translateY(-50%);
    cursor: pointer;

    &Secondary {
      right: 0;
    }

    &Filter {
      right: 13px;
    }
  }
}
