@use '../colors';
@use '../borders';
@use '../mixins';

.wrapper {
  display: block;
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;

    & ~ .input {
      padding-left: 28px;

      &.primary {
        padding-bottom: 3px;
        padding-left: 39px;
      }
    }
  }

  .input {
    @include mixins.style-font(16px, 150%, 400);
    vertical-align: middle;
    width: 100%;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      @include mixins.style-font(16px, 150%, 400);
    }

    &.tableView {
      @include mixins.style-font(12px, 100%, 400);

      &::placeholder {
        @include mixins.style-font(12px, 100%, 400);
      }
    }

    &.filterView {
      @include mixins.style-font(12px, 100%, 400);
      &::placeholder {
        @include mixins.style-font(12px, 100%, 400);
        color: colors.$placeholder-grey-secondary;
      }
    }

    &.primary {
      height: 47px;
      padding-left: 17px;
      background-color: colors.$white;
      border: 1px solid colors.$border-grey;
      border-radius: borders.$radius-md;

      &.filterView {
        height: 27px;
        border-radius: 10px;
      }

      &:focus {
        outline: none;
        border: 1px solid colors.$blue;
      }

      // autocomplete
      &:-webkit-autofill,
      &:-webkit-autofill:active {
        border: 1px solid colors.$border-grey;
      }

      // autocomplete
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:focus &:-webkit-autofill {
        outline: none;
        border: 1px solid colors.$blue;
      }

      &::placeholder {
        color: colors.$placeholder-grey-secondary;
      }
    }

    &.secondary {
      height: 24px;
      background: none;
      border: none;
      border-bottom: 1px solid colors.$border-grey-secondary;

      &.filterView {
        border-color: colors.$filter-border;

        &::placeholder {
          color: colors.$filter-placeholder;
        }
      }

      // autocomplete
      &:-webkit-autofill,
      &:-webkit-autofill:active {
        border-bottom: 1px solid colors.$border-grey-secondary;
      }

      // autocomplete
      &:-webkit-autofill:focus,
      &:-webkit-autofill:focus &:-webkit-autofill,
      &:-webkit-autofill:hover {
        outline: none;
        border-bottom: 1px solid colors.$blue;
      }

      &::placeholder {
        color: colors.$placeholder-grey-secondary;
      }

      &:focus {
        outline: none;
        border-bottom: 1px solid colors.$blue;
      }
    }

    &:hover {
      border-color: colors.$blue;
    }

    // autocomplete
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:focus &:-webkit-autofill,
    &:-webkit-autofill:active {
      @include mixins.style-font(16px, 150%, 400);
      -webkit-text-fill-color: colors.$black;
      -webkit-box-shadow: 0 0 0px 1000px colors.$white inset;
      box-shadow: 0 0 0px 1000px colors.$white inset;
      caret-color: colors.$black;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.closeBtn {
  position: absolute;
  right: 0;
  margin: 5px -11px 0 0;
  cursor: pointer;

  &Primary {
    top: 50%;
    transform: translateY(-50%);
    right: 33px;
    margin: 0 -15px 0 0;

    ~ .input {
      padding-right: 40px;
    }
  }
  &.filterView {
    right: 29px;
  }
}
