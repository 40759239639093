@use '../borders';
@use '../colors';

.scroll {
  overflow: auto;
  height: 100%;
  width: 100%;
}

.cards {
  display: flex;
}

.vertical {
  overflow: hidden auto;
}

.horizontal {
  overflow: auto hidden;
}

.primary {
  scrollbar-width: 7px;
  scrollbar-color: colors.$scrollbar-thumb colors.$scrollbar-track;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: borders.$radius-scroll;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: borders.$radius-scroll;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}

.white {
  /* Track */
  &::-webkit-scrollbar-track {
    background: colors.$scrollbar-track;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: colors.$scrollbar-thumb;
  }
}

.blue {
  /* Track */
  &::-webkit-scrollbar-track {
    background: colors.$blue;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.54);
  }
}

.secondary {
  margin-right: 4px;
  scrollbar-width: 4px;
  scrollbar-color: colors.$scrollbar-thumb colors.$scrollbar-track;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    margin: 10px;
    border-radius: 4px;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: borders.$radius-scroll;
    border: none;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}

.hidden {
  margin-right: 0;

  &::-webkit-scrollbar-track {
    margin: 0;
    border-radius: borders.$radius-scroll;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
