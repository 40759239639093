@use '../colors';
@use '../mixins';

.button {
  align-items: center;
  color: colors.$grey;
  @include mixins.style-font(16px, 160%, 500);
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  display: inline;
  border-bottom: 1px solid colors.$grey;

  > *:not(:last-child) {
    margin-right: 6px;
  }
  &:hover {
    border-bottom: 1px solid colors.$blue;
  }
  &:focus {
    border-bottom: 1px solid colors.$blue;
  }
  &:active {
    border-bottom: 1px solid colors.$blue;
  }
}

.menu {
  .menuItem {
    @include mixins.style-font(14px, 180%, 400);
    width: 153px;
    display: block;

    color: colors.$blue;
    padding-left: 10px;
    cursor: pointer;
  }
}

.back {
  padding: 3px 0;
  margin-top: 4px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  background-color: colors.$white;
  border: 1px solid colors.$border-grey-secondary;
  border-radius: 10px;
}
