.wrapper {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 0;

  .main {
    padding: 0 111px 50px 111px;
  }

  > *:not(:last-child) {
    margin-bottom: 49px;
  }

  header {
    margin-bottom: 65px;
  }
}

@media only screen and (min-width: 768px) {
  header {
    margin-bottom: 0px;
  }
}
