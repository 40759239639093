@use '../../colors';
@use '../../borders';
@use '../../mixins';

.cardTableBody {
  max-height: 200px;
  overflow-y: auto;

  .noData {
    width: 100%;
    height: 100%;
    @include mixins.center-flex();
    color: colors.$white;
    @include mixins.style-font(16px, 120%, 500);
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    padding: 12px 10px;
    color: colors.$white;

    &:nth-child(odd) {
      background-color: colors.$row-blue;
      border-radius: borders.$radius-sm;
    }

    .key {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      @include mixins.style-font(16px, 16px, 500);
      margin-right: 30px;
    }

    .bigKey {
      max-width: 450px;
    }

    .value {
      @include mixins.style-font(16px, 16px, 700);
    }
  }
}
