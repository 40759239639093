.loginForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 401px;
  margin-right: 50px;
  margin-right: 298px;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
}
