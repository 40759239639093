.cards {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;

    .card {
      width: 204px;
      padding: 23px 23px 21px 23px;
    }

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}
