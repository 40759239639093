$fontFamily: Inter, Roboto, Helvetica, sans-serif;

@mixin style-font($fontSize, $lineHeight, $fontWeight: normal) {
  font-family: $fontFamily;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: $fontWeight;
}

@mixin center-flex() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin center-margin() {
  margin-right: auto;
  margin-left: auto;
}
