@use '../../../colors';
@use '../../../mixins';

.wrapper {
  width: 100%;
  position: relative;

  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &Primary {
      right: 12px;
    }
  }
}
