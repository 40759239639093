@use '../colors';
@use '../mixins';
@use '../borders';

.button {
  @include mixins.center-flex();
  width: 100%;
  height: 48px;
  border: none;
  border-radius: borders.$radius-md;
  cursor: pointer;

  > *:not(:last-child) {
    margin-right: 15px;
  }
}

.xs {
  min-width: 47px;
  max-width: 59px;
  max-height: 26px;
  @include mixins.style-font(14px, 100%, 700);
}

.largerXs {
  min-width: 47px;
  max-width: 70px;
  max-height: 26px;
  @include mixins.style-font(14px, 100%, 700);
}

.longerXs {
  min-width: 47px;
  max-width: 151px;
  max-height: 26px;
  @include mixins.style-font(14px, 100%, 700);
}

.preSm {
  max-width: 85px;
  @include mixins.style-font(16px, 100%, 700);
}

.sm {
  max-width: 124px;
  @include mixins.style-font(16px, 100%, 700);
  flex-flow: row-reverse;
  > *:not(:last-child) {
    margin-right: 0px;
    margin-left: 15px;
  }
}

.md {
  max-width: 161px;
  @include mixins.style-font(16px, 100%, 700);
}

.lg {
  max-width: 221px;
  @include mixins.style-font(16px, 100%, 700);
}

.primary {
  background-color: colors.$blue;
  color: colors.$white;
}

.secondary {
  background-color: colors.$lightBlue;
  color: colors.$blue;
}

.ghost {
  background: none;
  color: colors.$blue;
  border: 1px solid colors.$blue;
}

.transparent {
  background: none;
  color: colors.$blue;
}
