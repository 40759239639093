@use '../colors';
@use '../mixins';

.heading {
  margin: 0;
}

.h1 {
  @include mixins.style-font(40px, 48px, 700);
}

.h2 {
  @include mixins.style-font(24px, 100%, 700);
}

.h3 {
  @include mixins.style-font(18px, 100%, 500);
}

.h4 {
  @include mixins.style-font(14px, 100%, 500);
}

.primary {
  color: colors.$grey;
}

.cardName {
  color: colors.$white;
}

.secondary {
  color: colors.$black;
}

.modal {
  color: colors.$black;
  line-height: 140%;
}
