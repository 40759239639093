@use '../../../components/mixins';
@use '../../../components/colors';

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 298px;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }

  .copyright {
    @include mixins.style-font(14px, 17px, 400);
    color: colors.$lightGrey;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.modal {
  margin-right: 0px;
  justify-content: center;
}
