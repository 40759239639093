.charts {
  display: flex;
  flex-direction: column;
  margin-bottom: 59px;

  .periodButtons {
    display: flex;

    > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  .button {
    max-width: 100px;
    padding: 6px 10px;
    width: 90px;
  }

  .row {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 40px;
    }
  }

  > *:not(:last-child) {
    margin-bottom: 24px;
  }
}
