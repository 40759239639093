@use '../../../components/mixins';

.wrapper {
  padding: 24px 12px 24px 24px;
}

.noData {
  @include mixins.style-font(16px, 100%, 500);
  @include mixins.center-flex();
  padding: 30px;
}
