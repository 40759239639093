@use '../../../colors';
@use '../../../mixins';

.menu {
  display: flex;
  align-items: center;
  margin-top: 36px;

  .greeting {
    display: flex;
    align-items: center;
    color: colors.$grey;
    @include mixins.style-font(16px, 160%, 500);

    .span {
      display: inline;
      border-bottom: 1px solid colors.$blue;
      cursor: pointer;
    }

    > *:not(:last-child) {
      margin-right: 6px;
    }
  }

  .logout {
    display: flex;
    align-items: center;
    color: colors.$dullGrey;
    @include mixins.style-font(16px, 160%, 500);
    background: none;
    border: none;
    cursor: pointer;

    > *:not(:last-child) {
      margin-right: 6px;
    }
  }

  > *:not(:last-child) {
    margin-right: 24px;
  }
}
