@use '../../components/mixins';

.dateTime {
  display: flex;
  flex-direction: column;

  .time {
    @include mixins.style-font(10px, 140%, 400);
  }
}

.currency {
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 4px;
  }
}
