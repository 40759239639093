.cardTableHeader {
  display: flex;
  align-items: center;

  .heading {
    text-transform: capitalize;
  }

  > *:not(:last-child) {
    margin-right: 10px;
  }
}
