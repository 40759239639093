.inputRows {
  max-height: 300px;
  overflow-y: auto;

  .inputRow {
    display: flex;
    flex-direction: column;

    > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
}
