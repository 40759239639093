@use '../../mixins';

.wrapper {
  width: 100vw;
  height: 100vh;
  padding: 25px 0;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    // max-width: 1216px;
    padding: 0 111px 50px 111px;
    // @include mixins.center-margin();

    header {
      margin-bottom: 65px;
    }
  }
}
