@use '../mixins';
@use '../colors';
@use '../borders';

.recharts-cartesian-axis-tick {
  @include mixins.style-font(12px, 100%, 500);
  color: colors.$black;
}

.custom-tooltip {
  background-color: colors.$white;
  padding: 5px;
  @include mixins.style-font(12px, 100%, 500);
  color: colors.$black;
  border: 1px solid colors.$border-grey;
  border-radius: borders.$radius-xs;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 0 10px;
  .label {
    margin-bottom: 3px;
  }
  .value {
    @include mixins.style-font(12px, 100%, 900);
  }
}
