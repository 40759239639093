@use '../../../components/mixins';
@use '../../../components/colors';
.wrapper {
  padding: 24px 12px 24px 24px;
}

.modal {
  width: 610px;
}

.scroll {
  height: 500px;
}

.noData {
  @include mixins.style-font(16px, 100%, 500);
  @include mixins.center-flex();
  padding: 30px;
}

.h2Header {
  @include mixins.style-font(24px, 100%, 500);
}
.enabled {
  text-transform: capitalize;
  color: colors.$green;
}

.disabled {
  text-transform: capitalize;
  color: colors.$crimson;
}

.headerRow {
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 32px;
  }
}
