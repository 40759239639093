.header {
  margin-bottom: 24px;

  &Row {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 32px;
    }
  }

  > *:not(:last-child) {
    margin-bottom: 30px;
  }
}
