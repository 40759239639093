@use '../../borders';
@use '../../colors';
@use '../../mixins';

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33px;
  padding: 7px;
  border-radius: borders.$radius-sm;

  .control {
    @include mixins.center-flex();
    width: 30px;
    cursor: pointer;

    &.active {
      border-radius: borders.$radius-sm;
    }
  }
}
