@use '../colors';
@use '../borders';
@use '../mixins';

.wrapper {
  display: flex;
  //   border: 1px solid colors.$pagination-grey;
  //   border-radius: borders.$radius-sm;

  .current {
    background-color: colors.$pagination-grey;
    color: colors.$pagination-darkgrey;
  }

  button {
    @include mixins.center-flex();
    min-width: 34px;
    height: 34px;
    background: none;
    cursor: pointer;
    color: colors.$blue;
    @include mixins.style-font(14px, 16px, 500);
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: colors.$pagination-grey;
    padding-left: 13px !important;
    padding-right: 13px !important;

    &:first-child {
      border-left-width: 1px;
      border-radius: 10px 0 0 10px;
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
}
