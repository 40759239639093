@use '../../../components/colors';

.enabled {
  text-transform: capitalize;
  color: colors.$green;
}

.disabled {
  text-transform: capitalize;
  color: colors.$crimson;
}
